import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import classNames from 'classnames';
import * as fonts from 'Themes/fonts';
import { useStyle } from 'Themes/theme';
import { i18n } from 'next-i18next';
import ErrorBoundary from 'Components/ErrorBoundary';

import '../index.scss';

import sD from '../styles/ThemeDansskolanentre.scss';
import sK from '../styles/ThemeKulturama.scss';
import sM from '../styles/ThemeMedborgarskolan.scss';
import sP from '../styles/ThemePahlmans.scss';
import sPGy from '../styles/ThemePahlmansGy.scss';
import sT from '../styles/ThemeTillskararakademin.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    PahlmansGy: sPGy,
    Tillskararakademin: sT,
};

const fallbackTheme = process?.env?.SITE_NAME || 'Medborgarskolan';

function MyApp({ Component, pageProps }) {
    const currentTheme = pageProps?.componentProps?.theme || fallbackTheme;

    const cssVars = useStyle(styles, currentTheme);

    /**
     * Load theme fonts here, even though they aren't added here
     * The font variables are added as class names to _document.js instead
     */
    const fontTheme = currentTheme === 'PahlmansGy' ? 'Pahlmans' : currentTheme;
    const themeFonts = useStyle(fonts, fontTheme);

    if(i18n.options.defaultNS !== currentTheme.toLowerCase()) {
        i18n.setDefaultNamespace(currentTheme.toLowerCase());
    }
    return (
        <>
            <Head>
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Head>
            <style jsx global>
                {`${cssVars}`}
            </style>
            <ErrorBoundary>
                <Component {...pageProps} />
            </ErrorBoundary>
        </>
    );
}

export default appWithTranslation(MyApp);
